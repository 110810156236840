<style></style>
<script>
import {mapGetters} from "vuex";
import {with_acrf_token} from "@/methods";

export default {
  props: ['cftools_id', 'account'],
  computed: {
    ...mapGetters([
      'getDTLocale'
    ]),
  },
  name: "AccountConnections",
  methods: {
    removeDiscord() {
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/remove-discord', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
            .then(response => {
              if(response.ok || response.status === 400){
                return response.json();
              } else {
                if(response.status === 429) {
                  this.$swal({
                    icon: 'warning',
                    text: this.$t('error.server.ratelimit.message'),
                    title: this.$t('error.server.ratelimit.title')
                  });
                } else
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
              }
            })
            .then(data => {
              if(!data) return;
              if(data.status) {
                this.account.connections.discord = null;
              } else {
                this.$swal({
                  icon: 'error',
                  text: this.$t('account.connections.error')
                });
              }
            })
            .catch(error => {
              console.log(`[ERROR] ${error}`);
              this.$swal({
                icon: 'error',
                text: this.$t('account.connections.error')
              });
            });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
    },
    removeTwitch() {
      with_acrf_token().then((r) => {
        let payload = {
          acsrf_token: r.acsrf_token
        };
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/remove-twitch', {
          method: 'POST',
          body: JSON.stringify(payload),
          credentials: 'include'
        })
            .then(response => {
              if(response.ok || response.status === 400){
                return response.json();
              } else {
                if(response.status === 429) {
                  this.$swal({
                    icon: 'warning',
                    text: this.$t('error.server.ratelimit.message'),
                    title: this.$t('error.server.ratelimit.title')
                  });
                } else
                  throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
              }
            })
            .then(data => {
              if(!data) return;
              if(data.status) {
                this.account.connections.twitch = null;
              } else {
                this.$swal({
                  icon: 'error',
                  text: this.$t('account.connections.error')
                });
              }
            })
            .catch(error => {
              console.log(`[ERROR] ${error}`);
              this.$swal({
                icon: 'error',
                text: this.$t('account.connections.error')
              });
            });
      }).catch(error => {
        console.log('[ERROR] Failed to request acrf token');
        this.$swal({
          icon: 'error',
          text: this.$t('error.server.generic.message')
        });
      });
    }
  },
  data() {
    return {
      twitch_add_url: process.env.VUE_APP_OLYMP + '/v1/twitch/oauth',
      twitch_rem_url: process.env.VUE_APP_OLYMP + '/v1/twitch/remove',
      discord_add_url: process.env.VUE_APP_OLYMP + '/v1/discord/oauth',
      discord_rem_url: process.env.VUE_APP_OLYMP + '/v1/discord/remove',
    }
  }
}
</script>

<template>
  <div>
    <div v-if="account.connections">
      <div class="row">
        <div class="col-lg-11">
          <div class="row">
            <div class="col">
              <div class="card">
                <div class="row">
                  <div class="col">
                    <div class="card">
                      <div class="card-body">
                        <div role="tablist">
                          <!-- Account connections card. -->
                          <b-card no-body class="shadow-none">
                              <b-card-header header-tag="header" role="tab">
                                <h3 class="m-0 d-inline-flex">
                                  {{ $t('account.connections.description') }}
                                </h3>
                              </b-card-header>
                              <b-card-body>
                                <b-card-text>
                                  <template >
                                    <div class="row">
                                      <div class="col-lg-1 m-auto justify-content-center text-center">
                                        <i class="fab fa-discord text-white" style="font-size: 48px;"></i>
                                      </div>
                                      <div class="col-sm m-auto text-white font-weight-bold" v-if="account.connections.discord">
                                          {{ account.connections.discord.id }}
                                        <small class="text-muted pl-1">
                                          {{ $t('account.connections.date') }}
                                          {{ $d(parseDate(account.connections.discord.created_at), 'datetime', getDTLocale()) }}
                                        </small>
                                        <a v-on:click="removeDiscord" href="#" class="float-right font-weight-bold">
                                          <i class="fad fa-unlink"></i> {{ $t('account.connections.remove') }}
                                        </a>
                                      </div>
                                      <div class="col-sm m-auto text-white" v-else>
                                        <a :href="discord_add_url" class="float-left font-weight-bold">
                                          <i class="fad fa-link"></i> {{ $t('account.connections.connect') }}
                                        </a>
                                      </div>
                                    </div>
                                    <div class="row pt-2">
                                      <div class="col-sm-1 m-auto justify-content-center text-center">
                                        <i class="fab fa-twitch text-white" style="font-size: 48px;"></i>
                                      </div>
                                      <div class="col-sm m-auto text-white font-weight-bold" v-if="account.connections.twitch">
                                        {{ account.connections.twitch.id }}
                                        <small class="text-muted pl-1">
                                          {{ $t('account.connections.date') }}
                                          {{ $d(parseDate(account.connections.twitch.created_at), 'datetime', getDTLocale()) }}
                                        </small>
                                        <a v-on:click="removeTwitch" href="#" class="float-right font-weight-bold">
                                          <i class="fad fa-unlink"></i> {{ $t('account.connections.remove') }}
                                        </a>
                                      </div>
                                      <div class="col-sm m-auto text-white" v-else>
                                        <a :href="twitch_add_url" class="float-left font-weight-bold">
                                          <i class="fad fa-link"></i> {{ $t('account.connections.connect') }}
                                        </a>
                                      </div>
                                    </div>
                                  </template>
                                </b-card-text>
                              </b-card-body>
                          </b-card>
                          <!-- Account connections card end. -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="account.connections === {} || !account">
      <div class="row">
        <div class="col-lg">
          <div class="card border border-danger">
            <div class="card-header bg-transparent border-danger">
              <h5 class="my-0 text-danger">
                <i class="far fa-exclamation-circle mr-3"></i>
                {{ $t('account.error.overview.title') }}
              </h5>
            </div>
            <div class="card-body">
              <h5 class="card-title mt-0"> {{ $t('account.error.overview.description') }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>